var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$t('Request Manual Time'),"no-fade":"","visible":_vm.visible,"ok-only":"","ok-title":_vm.$t('Request'),"ok-disabled":_vm.isProcessing,"no-close-on-backdrop":""},on:{"ok":_vm.validationForm,"close":function($event){return _vm.$emit('close')},"hidden":function () { _vm.$emit('close'); _vm.remarks = '' }}},[_c('validation-observer',{ref:"manualTimeForm"},[_c('b-overlay',{attrs:{"show":_vm.isProcessing}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Date')}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-2",attrs:{"calendar-width":"100%","max":_vm.TODAY(),"date-format-options":_vm.COMMON.DATE_PICKER_FORMAT},on:{"input":_vm.validateTime},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"7"}},[_c('validation-provider',{attrs:{"name":"timezone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.timezone')}},[_c('v-select',{attrs:{"options":_vm.timezones,"label":"title","reduce":function (item) { return item.value; },"clearable":false},on:{"input":_vm.validateTime},model:{value:(_vm.selectedTimezone),callback:function ($$v) {_vm.selectedTimezone=$$v},expression:"selectedTimezone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"start time","rules":"required|is-before-current-time:start"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Start Time')}},[_c('b-form-input',{attrs:{"locale":"en","type":"time","step":"1","format":"HH:mm:ss","max":_vm.timeParms.end,"onclick":"showPicker()"},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"end time","rules":("required|is-before-start-time:" + _vm.startTime + "|is-before-current-time:end")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('End Time')}},[_c('b-form-input',{attrs:{"type":"time","step":"1","format":"HH:mm:ss","onclick":"showPicker()","locale":"en"},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"remarks","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Remarks')}},[_c('b-form-textarea',{attrs:{"placeholder":"Remarks","locale":"en"},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"md":"12"}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ClockIcon","size":"16"}}),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Manual Time'))+": "+_vm._s(_vm.getAddedTime)+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }