<template>
  <div>
    <b-card body-class="p-0">
      <b-table
        :fields="columns"
        :items="tableRows"
        show-empty
      >
        <template #head()="data">
          <span class="text-capitalize">{{ data.label }}</span>
        </template>
        <template #cell(startedAt)="data">
          {{ timeWithTimeZone(data.item.startedAt, timeZone, COMMON.DATE_TIME_FORMAT) }}
        </template>
        <template #cell(endedAt)="data">
          {{ timeWithTimeZone(data.item.endedAt, timeZone, COMMON.DATE_TIME_FORMAT) }}
        </template>
        <template #cell(duration)="data">
          {{ CONVERT_HM(data.item.duration) }}
        </template>
        <template #cell(action)="data">
          <b-button
            variant="outline-success badge-glow"
            class="btn-icon mr-2"
            @click="manualTimeToAccept = data.item"
          >
            <feather-icon icon="CheckIcon" />
          </b-button>
          <b-button
            variant="outline-danger badge-glow"
            class="btn-icon"
            @click="manualTimeToReject = data.item"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </template>
        <template #empty>
          {{ $t('reports.no-requested-time') }}
        </template>
      </b-table>
    </b-card>
    <confirm-modal
      :delete-params="{
        title: $t('Confirm'),
        body: $t('reports.accept-confirmation')
      }"
      variant="success"
      :visible="!!manualTimeToAccept"
      :processing="isProcessing"
      @close="manualTimeToAccept = null"
      @onConfirm="acceptManualTime"
    />
    <confirm-modal
      :delete-params="{
        title: $t('Confirm'),
        body: $t('reports.reject-confirmation')
      }"
      :processing="isProcessing"
      variant="danger"
      :visible="!!manualTimeToReject"
      @close="manualTimeToReject = null"
      @onConfirm="rejectManualTime"
    />
  </div>
</template>
<script>
import { BTable, BCard, BButton } from 'bootstrap-vue'
import ConfirmModal from '@/views/common/components/ConfirmModal.vue'
import useApollo from '@/plugins/graphql/useApollo'

export default {
  components: {
    BTable,
    BCard,
    BButton,
    ConfirmModal,
  },
  props: {
    requestedManualTime: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columns: [
        { key: 'startedAt', label: this.$t('Start Time') },
        { key: 'endedAt', label: this.$t('End Time') },
        { key: 'duration', label: this.$t('Duration') },
        { key: 'remarks', label: this.$t('Remarks') },
        { key: 'action', label: this.$t('Action') },
      ],
      manualTimeToAccept: null,
      manualTimeToReject: null,
      isProcessing: false,
    }
  },
  computed: {
    timeZone() {
      return this.$store.getters['project/FILTERED_TIMEZONE_OR_DEFAULT']?.value
    },
    tableRows() {
      const tableData = this.requestedManualTime?.data || []
      return tableData.map(table => ({
        ...table,
        duration: this.GET_TIME_DIFFERENCE(table.startedAt, table.endedAt),
      }))
    },
  },
  methods: {
    updateTime(decline, record) {
      return useApollo.activities.updatePendingManualTime({
        projectUid: this.$store.state.project.selectedProject,
        userUid: record.user.uuid,
        record: Number(record.id),
        decline,
      })
    },
    acceptManualTime() {
      this.isProcessing = true
      this.updateTime(false, this.manualTimeToAccept).then(response => {
        this.$emit('refetch')
        this.manualTimeToAccept = null
        this.showSuccess(response.data?.approveManualTimeRecord?.message)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
    rejectManualTime() {
      this.isProcessing = true
      this.updateTime(true, this.manualTimeToReject).then(response => {
        this.$emit('refetch')
        this.manualTimeToReject = null
        this.showSuccess(response.data?.approveManualTimeRecord?.message)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
  },
}
</script>
