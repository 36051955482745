<template>
  <b-modal
    :title="$t('Request Manual Time')"
    no-fade
    :visible="visible"
    ok-only
    :ok-title="$t('Request')"
    :ok-disabled="isProcessing"
    no-close-on-backdrop
    @ok="validationForm"
    @close="$emit('close')"
    @hidden="() => { $emit('close'); remarks = '' }"
  >
    <validation-observer ref="manualTimeForm">
      <b-overlay :show="isProcessing">
        <b-form>
          <b-row>
            <b-col md="5">
              <b-form-group :label="$t('Date')">
                <validation-provider
                  #default="{ errors }"
                  name="date"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="date"
                    calendar-width="100%"
                    class="mb-2"
                    :max="TODAY()"
                    :date-format-options="COMMON.DATE_PICKER_FORMAT"
                    @input="validateTime"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="7">
              <validation-provider
                #default="{ errors }"
                name="timezone"
                rules="required"
              >
                <b-form-group :label="$t('labels.timezone')">
                  <v-select
                    v-model="selectedTimezone"
                    :options="timezones"
                    label="title"
                    :reduce="item => item.value"
                    :clearable="false"
                    @input="validateTime"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="start time"
                  rules="required|is-before-current-time:start"
                >
                  <b-form-group :label="$t('Start Time')">
                    <b-form-input
                      v-model="startTime"
                      locale="en"
                      type="time"
                      step="1"
                      format="HH:mm:ss"
                      :max="timeParms.end"
                      onclick="showPicker()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="end time"
                  :rules="`required|is-before-start-time:${startTime}|is-before-current-time:end`"
                >
                  <b-form-group :label="$t('End Time')">
                    <b-form-input
                      v-model="endTime"
                      type="time"
                      step="1"
                      format="HH:mm:ss"
                      onclick="showPicker()"
                      locale="en"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="remarks"
                  rules="required"
                >
                  <b-form-group :label="$t('Remarks')">
                    <b-form-textarea
                      v-model="remarks"
                      placeholder="Remarks"
                      locale="en"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              class="d-flex align-items-center"
              md="12"
            >
              <feather-icon
                icon="ClockIcon"
                class="mr-1"
                size="16"
              />
              <p class="mb-0">
                {{ $t('Manual Time') }}: {{ getAddedTime }}
              </p>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, validate, extend,
} from 'vee-validate'
import {
  BFormGroup, BForm, BRow, BCol, BFormTextarea, BFormInput, BOverlay, BFormDatepicker,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import useApollo from '@/plugins/graphql/useApollo'
import vSelect from 'vue-select'

const moment = require('moment')

extend('is-before-start-time', (value, [startTime]) => {
  const endTime = moment(value, 'HH:mm:ss')
  const start = moment(startTime, 'HH:mm:ss')
  if (endTime.isSameOrBefore(start)) return 'The end time should be after the start time'
  return true
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormTextarea,
    BFormInput,
    BFormDatepicker,
    vSelect,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    timeParms: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: String,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
    timezone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      startTime: this.TODAY('HH:mm:ss'),
      endTime: this.TODAY('HH:mm:ss'),
      date: this.TODAY(),
      selectedTimezone: null,
      required,
      email,
      isProcessing: false,
      remarks: '',
    }
  },
  computed: {
    minStartTime() {
      return moment(`${this.day} ${this.timeParms.start}`, 'YYY-MM-DD hh:mm:ss')
    },
    maxEndTime() {
      if (this.timeParms.end === '00:00:00') {
        return moment(this.minStartTime).add(1, 'days').startOf('day')
      }
      return moment(`${this.date} ${this.timeParms.end}`, 'YYY-MM-DD hh:mm:ss')
    },
    getAddedTime() {
      const start = moment(this.startTime, 'HH:mm:ss a')
      const end = moment(this.endTime, 'HH:mm:ss a')

      if (end.isBefore(start)) return '0 hour 0 minute'
      const diff = moment.duration(end.diff(start))

      const hours = Math.floor(diff.asHours())
      const minutes = Math.floor(diff.asMinutes() % 60)

      return `${hours ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : ''} ${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`
    },
    timezones() {
      return this.$store.state.project.timezones
    },
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.startTime = this.timeParms.start === '0:00' ? '00:00:00' : this.timeParms.start
        this.endTime = this.timeParms.end
      }
    },
    timezone: {
      handler(value) {
        this.selectedTimezone = value
      },
      immediate: true,
    },
    day: {
      handler(value) {
        this.date = value
      },
      immediate: true,
    },
  },
  mounted() {
    extend('is-before-current-time', (value, [type]) => {
      if (moment(this.date).isSame(this.TODAY())) {
        const input = moment(`${this.date} ${value}`)
        const current = this.timeWithTimeZone(moment(), this.selectedTimezone, 'YYYY-MM-DD HH:mm:ss')
        if (input.isAfter(current)) {
          return `The ${type} time cannot exceed current time in ${this.timezone}`
        }
      }
      return true
    })
  },
  methods: {
    validationForm(bvModal) {
      bvModal.preventDefault()
      this.$refs.manualTimeForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.activities.updateManualTime({
            startTime: `${this.date} ${this.startTime}`,
            endTime: `${this.date} ${this.endTime}`,
            remarks: this.remarks,
            userUid: this.userId,
            projectUid: localStorage.selected_project,
          }).then(() => {
            this.showSuccess('Time added and waiting for approval')
            this.$emit('close')
            this.$emit('onSave')
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => { this.isProcessing = false })
        }
      })
    },
    async validateField(value, rules) {
      return validate(value, rules).then(result => result.errors)
    },
    async validateTime() {
      const { errors } = this.$refs.manualTimeForm
      const startTimeError = await this.validateField(this.startTime, 'required|is-before-current-time:start')
      const endTimeError = await this.validateField(this.endTime, 'required|is-before-current-time:end')

      this.$refs.manualTimeForm.setErrors({
        ...errors,
        'end time': endTimeError,
        'start time': startTimeError,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog {
  max-width: 560px;
}
::v-deep .vs__actions {
  display: none;
}
</style>
